import * as React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  MenuItem,
  Select,
} from "@mui/material";
import { InputBoxCard } from "./InputBoxCard";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function CreateProjectDialog({
  setOpen,
  open,
  showModal,
  setShowModal,
  setTitle,
  title,
  description,
  plateform,
  projectDate,
  setProjectDate,
  setDescription,
  dialogTitle,
  setPlateform,
  handleSave,
}) {
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "title") {
      setTitle(value);
    } else if (name === "description") {
      setDescription(value);
    } else if (name === "plateform") {
      setPlateform(value);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setShowModal(false);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    handleClose();
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleClose()}
        sx={{
          "& .MuiPaper-elevation": { width: "677px", p: "10px", pb: "14px" },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          className="create-project-dialog"
          id="alert-dialog-title"
          sx={{ paddingBottom: "0px" }}
        >
          {dialogTitle}
          <DialogContentText
            className="create-project-sub-dialog"
            id="alert-dialog-description"
          >
            Please fill all detail related of content
          </DialogContentText>
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent sx={{ paddingTop: "0px" }}>
            <InputBoxCard
              label="Title"
              handleChange={handleChange}
              inputType="text"
              defaultValue={title}
              name="title"
              placeholder="Enter Project Title"
              requiredState={true}
            />
            <InputBoxCard
              label="Description(Optional)"
              inputType="text"
              name="description"
              defaultValue={description}
              handleChange={handleChange}
              placeholder="Enter Description"
              showStar="none"
              requiredState={false}
            />
            <div
              className="create-project-container"
              style={{
                flexFlow: "row",
                justifyContent: "space-between",
                marginTop: "0px",
              }}
            >
              <div className="create-project-container">
                <span
                  className="create-project-title"
                  style={{ marginBottom: "8px" }}
                >
                  Platform(Optional)
                </span>
                <Select
                  name="plateform"
                  onChange={handleChange}
                  style={{
                    height: "38px",
                  }}
                  value={plateform}
                  sx={{
                    "& .MuiSelect-select": {
                      color: "#17173a !important",
                      fontFamily: '"Manrope", sans-serif !important',
                      fontSize: "14px !important",
                      fontWeight: "500 !important",
                    },
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="Youtube">Youtube</MenuItem>
                  <MenuItem value="Linkedin">Linkedin</MenuItem>
                  <MenuItem value="Instagram">Instagram</MenuItem>
                </Select>
              </div>
              <div
                className="create-project-container"
                style={{ marginLeft: "25px" }}
              >
                <span className="create-project-title">
                  Date of Publish<span style={{ color: "#f05c5c" }}>*</span>
                </span>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={["DatePicker"]}
                    style={{ height: "38px" }}
                  >
                    <DatePicker
                      className="date-picker"
                      sx={{
                        "& .MuiSvgIcon-root": { width: "20px" },
                        "& .MuiInputBase-input": {
                          padding: "8px 12px",
                          color: "#17173a !important",
                          fontFamily: '"Manrope", sans-serif !important',
                          fontSize: "14px !important",
                          fontWeight: "500 !important",
                        },
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                      onChange={(newValue) => setProjectDate(newValue)}
                      value={projectDate}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              className="create-project-dialog-cancel-btn"
              onClick={() => handleClose()}
            >
              Cancel
            </Button>
            <Button
              className="create-project-dialog-btn"
              type="submit"
              autoFocus
              onClick={handleSave}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
