import * as React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  IconButton,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
export default function ManagePlan({ setOpenManagePlan, openManagePlan }) {
  const handleClose = () => {
    setOpenManagePlan(false);
  };
  const handleSubmit = (event) => {
    handleClose();
  };
  return (
    <Dialog
      open={openManagePlan}
      onClose={() => handleClose()}
      sx={{
        "& .MuiPaper-elevation": {
          p: "10px 30px",
          pb: "36px",
        },
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        className="create-project-dialog"
        id="alert-dialog-title"
        sx={{ paddingBottom: "24px", pl: "0px" }}
      >
        Manage Plan
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 22,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box
        sx={{
          display: "flex",
          flexFlow: "row",
          justifyContent: "space-between",
        }}
      >
        <Box className="manage-project-plan-card" sx={{ marginRight: "30px" }}>
          <Box className="manage-project-plan-title">
            <DialogContentText
              className="manage-project-plan"
              id="alert-dialog-description"
            >
              Free Plan
              <img
                src="/images/emoji_fire_.svg"
                alt="fire"
                style={{ marginLeft: "4px" }}
              />
            </DialogContentText>
            <span className="manage-project-plan-price">$0 / 14 Days</span>
          </Box>
          <DialogContentText className="manage-project-plan-detail">
            10 Project 1GB <br />
            Cloud Storage <br />
            Up to 02 users
          </DialogContentText>
          <DialogActions sx={{ marginTop: "20px", paddingLeft: "0px" }}>
            <Button
              variant="outlined"
              className="manage-project-plan-btn"
              onClick={handleSubmit}
            >
              Current plan
            </Button>
          </DialogActions>
        </Box>
        <Box className="manage-project-plan-card">
          <Box className="manage-project-plan-title">
            <DialogContentText
              className="manage-project-plan"
              id="alert-dialog-description"
            >
              Pro Plan
              <img
                src="/images/emoji_rocket_.svg"
                alt="rocket"
                style={{ marginLeft: "4px" }}
              />
            </DialogContentText>
            <span className="manage-project-plan-price">
              $15 per user / month
            </span>
          </Box>
          <DialogContentText className="manage-project-plan-detail">
            10 Project 1GB <br />
            Cloud Storage <br />
            Up to 02 users
          </DialogContentText>
          <DialogActions sx={{ marginTop: "20px", marginLeft: "0px" }}>
            <Button
              className="create-project-dialog-btn"
              type="submit"
              sx={{ padding: "10px 16px" }}
              autoFocus
            >
              CHOOSE PLAN
            </Button>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  );
}
