import React from "react";
import { styled, alpha } from "@mui/material/styles";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  border: "1.8px solid #DFE1E6",
  borderRadius: "4px",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "400px",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#DFE1E6",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#DFE1E6",
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function SearchProject({ openSearch, setOpenSearch }) {
  const handleClickOpen = () => {
    setOpenSearch(true);
  };

  const handleClose = () => {
    setOpenSearch(false);
  };
  return (
    <Dialog
      fullScreen
      open={openSearch}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <Box sx={{ display: "flex", flexFlow: "column" }}>
        <AppBar sx={{ position: "relative", background: "white" }}>
          <Toolbar>
            <Search
              sx={{
                "& .MuiInputBase-input": {
                  color: "#17173A",
                  fontFamily: '"Manrope", sans-serif !important',
                  fontSize: "14px !important",
                  fontWeight: "500 !important",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#000",
                },
              }}
            >
              <SearchIconWrapper>
                <SearchIcon sx={{ fill: "#DFE1E6" }} />
              </SearchIconWrapper>
              <StyledInputBase
                sx={{ width: "100%" }}
                placeholder="Search or jump to..."
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
            <IconButton
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <img src="/images/close_dark.svg" alt="close" />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box className="search-box-default">
          <img src="/images/search-default-group.svg" alt="searh" />
        </Box>
      </Box>
    </Dialog>
  );
}
