import React, { useEffect, useState } from "react";
import { styled, useTheme, alpha } from "@mui/material/styles";
import { Button, Box } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DashboardTabs from "../components/DashboardTabs";
import ImageWithDropdown from "../components/ImageWithDropdown";
import listMenu from "../components/ListMenu.json";
import CreateProjectDialog from "./CreateProjectDialog";
import InviteTeam from "./InviteTeam";
import SearchProject from "./SearchProject";
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});
const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  border: "1.8px solid #DFE1E6",
  borderRadius: "4px",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "400px",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#DFE1E6",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#DFE1E6",
  },
}));
export default function Header({ updateListMenu }) {
  const [openSearch, setOpenSearch] = React.useState(false);
  const [showModal, setShowModal] = React.useState(true);
  const theme = useTheme();
  const [listMenuItem, setListMenuItem] = useState(listMenu);
  useEffect(() => {
    updateListMenu(listMenuItem);
  }, [listMenuItem]);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const [openInviteDialog, setOpenInviteDialog] = React.useState(false);
  const handleInviteDialog = () => {
    setOpenInviteDialog(true);
  };
  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));
  return (
    <Box sx={{ display: "flex" }}>
      <CreateProjectDialog
        dialogTitle="Create"
        title=""
        description=""
        plateform=""
        projectDate=""
        setOpen={setOpen}
        open={open}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <CssBaseline />
      <AppBar position="fixed" open={openDrawer} sx={{ bgcolor: "white" }}>
        <Toolbar>
          <img
            src="/images/logo.svg"
            alt="logo"
            onClick={handleDrawerOpen}
            className="nav-logo-icon"
          />
          <Search
            sx={{
              "& .MuiInputBase-root": { width: "100%" },
              "& .MuiInputBase-input": {
                color: "#17173A",
                fontFamily: '"Manrope", sans-serif !important',
                fontSize: "14px !important",
                fontWeight: "500 !important",
              },
              "& .MuiInputBase-input::placeholder": {
                color: "#000",
              },
            }}
          >
            <SearchIconWrapper>
              <SearchIcon sx={{ fill: "#DFE1E6" }} />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search or jump to..."
              inputProps={{ "aria-label": "search" }}
              onClick={() => setOpenSearch(true)}
            />
          </Search>
          <SearchProject
            setOpenSearch={setOpenSearch}
            openSearch={openSearch}
          />
          <Box
            sx={{
              right: "0",
              position: "absolute",
              flexFlow: "nowrap",
              display: "flex",
            }}
          >
            <Button
              variant="contained"
              className="dashboard-create-btn"
              sx={{ background: "#0065FF", marginRight: "10px" }}
              startIcon={<img alt="create" src="/images/plus-square.svg" />}
              onClick={() => handleClickOpen()}
            >
              <span>CREATE</span>
            </Button>
            <Button
              variant="outlined"
              className="dashboard-create-btn"
              sx={{ marginRight: "10px", color: "#0065FF" }}
            >
              <span style={{ color: "#0065FF" }}>CONNECT BRAND</span>
            </Button>
            <Button
              variant="outlined"
              className="dashboard-create-btn"
              onClick={handleInviteDialog}
              sx={{ marginRight: "10px", color: "#0065FF" }}
            >
              <span style={{ color: "#0065FF" }}>INVITE TEAMMATE</span>
            </Button>
            <ImageWithDropdown />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={openDrawer}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List sx={{ background: "#0065FF", marginTop: -1, height: "100%" }}>
          <DashboardTabs
            open={openDrawer}
            setListMenuItem={setListMenuItem}
            listMenuItem={listMenuItem}
          />
        </List>
        <Divider />
      </Drawer>
      <InviteTeam
        openInviteDialog={openInviteDialog}
        setOpenInviteDialog={setOpenInviteDialog}
      />
    </Box>
  );
}
