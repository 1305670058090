// store.js
import { configureStore } from "@reduxjs/toolkit";
import apiReducer from "./apiSlice";

export const store = configureStore({
  reducer: {
    items: apiReducer,
  },
});
export default store;
