import * as React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { InputBoxCard } from "./InputBoxCard";
export default function InviteTeam({ setOpenInviteDialog, openInviteDialog }) {
  const handleClose = () => {
    setOpenInviteDialog(false);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    handleClose();
  };
  return (
    <Dialog
      open={openInviteDialog}
      onClose={() => handleClose()}
      sx={{
        "& .MuiPaper-elevation": {
          width: "677px",
          p: "10px",
          pb: "0px",
          pt: "20px",
          display: "flex",
          flexFlow: "column",
          alignItems: "center",
        },
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        className="create-project-dialog"
        id="alert-dialog-title"
        sx={{ paddingBottom: "24px" }}
      >
        Invite teammate
      </DialogTitle>
      <form onSubmit={handleSubmit} className="signup-form">
        <DialogContentText
          className="create-project-sub-dialog"
          sx={{ paddingBottom: "40px" }}
          id="alert-dialog-description"
        >
          <InputBoxCard
            label="Email Address"
            inputType="email"
            placeholder="Eg. Neha@gmail.com"
            requiredState={true}
          />
          <InputBoxCard
            label="Role"
            inputType="text"
            placeholder="Ed. Editor"
            requiredState={false}
            showStar="none"
          />
          <DialogActions
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "36px",
            }}
          >
            <Button
              className="create-project-dialog-btn"
              type="submit"
              autoFocus
            >
              Invite
            </Button>
            <Button
              className="delete-project-dialog-cancel-btn"
              onClick={() => handleClose()}
            >
              Cancel
            </Button>
          </DialogActions>
        </DialogContentText>
      </form>
    </Dialog>
  );
}
