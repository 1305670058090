import * as React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  Box,
} from "@mui/material";
import { InputBoxCard } from "./InputBoxCard";
export default function EditPlateformDetails({
  setOpen,
  open,
  plateform,
  chipData,
  setChipData,
}) {
  //   const [selectedPlateform, setSelectedPlateform] = React.useState(plateform);
  const updateStatus = (index) => {
    const updatedChipData = chipData.map((item, i) =>
      i === index ? { ...item, status: !item.status } : item
    );
    setChipData(updatedChipData);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    // if (name === "title") {
    //   setProjectTitle(value);
    // } else if (name === "description") {
    //   setSelectedPlateform(value);
    // }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    handleClose();
    // Your form submission logic here
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleClose()}
        sx={{
          "& .MuiPaper-elevation": { width: "677px", p: "10px", pb: "14px" },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          className="create-project-dialog"
          id="alert-dialog-title"
          sx={{ paddingBottom: "20px" }}
        >
          Edit platform details
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent
            sx={{ paddingTop: "0px", flexFlow: "column", display: "flex" }}
          >
            <Box sx={{ display: "flex", flexFlow: "row" }}>
              <Box sx={{ width: "70%" }}>
                <InputBoxCard
                  label="Platform name"
                  handleChange={handleChange}
                  inputType="text"
                  name="plateform"
                  placeholder="Enter Plateform Name"
                  requiredState={false}
                  showStar="none"
                />
                <InputBoxCard
                  label="Description"
                  inputType="text"
                  name="description"
                  handleChange={handleChange}
                  placeholder="Enter Description"
                  showStar="none"
                  requiredState={false}
                />
              </Box>
              <Box
                className="setting-profile-edit"
                sx={{ left: "48px", marginTop: "20px" }}
              >
                <img
                  className="setting-profile-img"
                  src="/images/profile.png"
                  alt="Selected"
                  style={{ objectFit: "contain" }}
                />
              </Box>
            </Box>
            <div
              style={{
                marginTop: "20px",
                paddingRight: "20px",
              }}
            >
              <span className="edit-plateform-title">
                What’s the the name of your platform or team.
              </span>
            </div>
            <Box>
              {chipData.map((item, index) => (
                <Chip
                  className="signup-chip"
                  label={item.name}
                  onClick={() => updateStatus(index)}
                  style={{
                    marginTop: "20px",
                    marginRight: "10px",
                    border: "1px solid #dde2ee",
                    background: item.status ? "#0065FF" : "#f8f8f8",
                    color: item.status ? "#fff" : "#0065FF",
                  }}
                />
              ))}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              className="create-project-dialog-cancel-btn"
              onClick={() => handleClose()}
            >
              Cancel
            </Button>
            <Button
              className="create-project-dialog-btn"
              type="submit"
              autoFocus
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
