import React, { useEffect } from "react";
import Box from "@mui/system/Box";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import SignSideCard from "../../components/SignSideCard";
import {
  InputBoxCard,
  InputBoxPasswordCard,
} from "../../components/InputBoxCard";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../utils/actions";
import { clearData } from "../../utils/apiSlice";

export default function SignInUserDetails() {
  let navigate = useNavigate();

  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.items);

  const [credentials, setCredentials] = React.useState();
  const handleForgetPass = (event) => {
    navigate("/sign-in/forgot-pass");
  };
  useEffect(() => {
    console.log(99, error);

    if (data.userLogin) {
      localStorage.setItem("userId", data.userLogin.id);
      localStorage.setItem("user", data.email);
      navigate("/sign-in-verify");
    }
  }, [data]);
  const handleSubmit = (event) => {
    event.preventDefault();
    if (credentials.identifier && credentials.password) {
      dispatch(loginUser(credentials));
    }
  };

  const handleBack = () => {
    navigate("/sign-in");
  };
  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  return (
    <Box className="sigin-card">
      <SignSideCard />
      <Box className="sigin-form-container">
        <Button className="sign-in-btn" onClick={handleBack}>
          <img src="/images/arrow_back.svg" alt="back" />
          <span style={{ paddingLeft: "4px" }}>BACK</span>
        </Button>
        <span className="signin-title" style={{ marginTop: "50px" }}>
          Sign in to your account
        </span>
        <form
          onSubmit={handleSubmit}
          style={{ width: "65%", marginTop: "10px" }}
        >
          <InputBoxCard
            handleChange={(e) => handleChange(e)}
            name="identifier"
            label="Email address"
            defaultValue={data.email || ""}
            requiredState={true}
            inputType="email"
            placeholder="Enter Your Email Address"
          />
          <InputBoxPasswordCard
            requiredState={true}
            name="password"
            handleChange={(e) => handleChange(e)}
            label="Password"
            placeholder="Enter Password"
          />
          <Button
            variant="contained"
            className="dashboard-create-btn"
            type="submit"
            sx={{ background: "#0065FF", marginTop: "30px" }}
          >
            <span>SIGN IN</span>
          </Button>
        </form>
        <span className="signin-terms">
          Not remember password
          <span
            style={{ color: "#0065FF", cursor: "pointer", marginLeft: "4px" }}
            onClick={handleForgetPass}
          >
            forgot your password?
          </span>
        </span>
      </Box>
    </Box>
  );
}
