import { createSlice } from "@reduxjs/toolkit";

const apiSlice = createSlice({
  name: "items",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {
    fetchItemsStart(state) {
      state.loading = true;
    },
    fetchItemsSuccess(state, action) {
      const { key, value } = action.payload;
      console.log(key);
      state.loading = false;
      state.data = { ...state.data, [key]: value };
    },
    fetchItemsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    clearData(state) {
      state.data = []; // Set to an empty array to clear data
      state.loading = false;
      state.error = null;
    },
  },
});

export const {
  fetchItemsStart,
  fetchItemsSuccess,
  fetchItemsFailure,
  clearData,
} = apiSlice.actions;
export default apiSlice.reducer;
