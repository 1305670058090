import { Grid, useMediaQuery, Box } from "@mui/material";
import React, { useState } from "react";
import Header from "../components/Header";
import CreateProject from "./CreateProject";
import { styled } from "@mui/material/styles";
import MyContent from "./MyContent";
import Gallery from "./Gallery";
import Chart from "./Chart";
import Setting from "./Setting";
import ChatUs from "./ChatUs";
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

export default function Dashboard() {
  const [listMenuItem, setListMenuItem] = useState([]);
  const updateListMenu = (value) => {
    setListMenuItem(value);
  };

  const isMobile = useMediaQuery("(max-width: 600px)");
  return (
    <Box sx={{ display: "flex" }}>
      <Header updateListMenu={updateListMenu} />
      <div className="dashboard-container">
        <Grid container spacing={2}>
          <Grid item xs={9}>
            {listMenuItem[0]?.show && (
              <Box component="main" sx={{ pt: "40px" }}>
                <DrawerHeader />
                <CreateProject />
              </Box>
            )}
            {listMenuItem[1]?.show && (
              <Box component="main" sx={{ pt: "40px" }}>
                <DrawerHeader />
                <MyContent />
              </Box>
            )}
            {listMenuItem[2]?.show && (
              <Box component="main" sx={{ pt: "40px" }}>
                <DrawerHeader />
                <Gallery />
              </Box>
            )}
            {listMenuItem[3]?.show && (
              <Box component="main" sx={{ pt: "40px" }}>
                <DrawerHeader />
                <Chart />
              </Box>
            )}
            {listMenuItem[4]?.show && (
              <Box component="main" sx={{ pt: "40px" }}>
                <DrawerHeader />
                <Setting />
              </Box>
            )}
          </Grid>
          {!isMobile && (
            <Grid item xs={3}>
              <Box className="dashboard-chat-card">
                <DrawerHeader />
                <ChatUs />
              </Box>
            </Grid>
          )}
        </Grid>
      </div>
    </Box>
  );
}
