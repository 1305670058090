export const GET_ITEMS = (data) => {
  return `
    {
      usersPermissionsUsers(filters: {
        email: {
          eq: "${data.email}"
        }
      }) {
        data {
          id
          attributes {
            email
          }
        }
      }
    }
  `;
};
export const Get_WorkSpace_List = (email) => {
  return `
 {
  platforms(filters: {
    admin: {
      email: {
        eq: "${email}"
      }
    }
  }) {
    data {
      attributes {
        Name
        Description
        workspaces {
          data {
            id
            attributes {
              active
              name
              description
            }
          }
        }
      }
    }
  }
}
`;
};
