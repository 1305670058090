import * as React from "react";
import Box from "@mui/system/Box";
import { Typography } from "@mui/material";
// import { ApvFilePicker } from "@appvitalinc/file-upload-widget-api-react";

export default function Gallery() {
  const data = [
    { name: "LOANS JALDI LOEP", fileInfo: "02 files  |  5.2GB" },
    {
      name: "Motivation career Motivation career",
      fileInfo: "10 files  |  5.2GB",
    },
    { name: "LOANS JALDI LOEP", fileInfo: "02 files  |  5.2GB" },
    { name: "Motivation career", fileInfo: "10 files  |  5.2GB" },
  ];
  const [upload, setUpload] = React.useState(true);
  const [folderName, setFolderName] = React.useState(false);

  // const [open, setOpen] = React.useState(false);
  // const apikey = "YOUR_API_KEY";
  // const options = {
  //   multiple: false,
  //   outputfileoptions: {
  //     width: 500,
  //     height: 281,
  //     resizetype: "fit",
  //     resizeconflict: "pad",
  //     storage: [
  //       {
  //         name: "AWS-S3-Storage-Demo",
  //         path: "/file-picker-demo/",
  //       },
  //     ],
  //   },
  //   inputfileoptions: {
  //     allowedfileformats: "jpg,jpeg,gif,bmp,png,webp,zip,pdf",
  //     maxfilesize: 700000000,
  //     note: "File size restricted to: 10000 KB / File type restricted to: JPG, PNG, JPEG, PDF, DOC, DOCX, TXT, XML and ZIP.",
  //   },
  // };

  // const onClose = () => {
  //   setOpen(false);
  // };

  // const onCancel = () => {
  //   console.log("On Cancel");
  // };

  // const onUploadDone = (data) => {
  //   console.log("onUploadStart", data);
  // };

  // const onUploadStart = () => {
  //   console.log("onUploadStart");
  // };

  // const onUploadError = (data) => {
  //   console.log("onUploadError", data);
  // };
  return (
    <Box
      className="dashboard-title-card"
      sx={{ marginLeft: "30px", marginBottom: "50px" }}
    >
      <Box
        sx={{
          display: "flex",
          flexFlow: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", flexFlow: "column" }}>
          <Typography className="dashboard-title">Gallery</Typography>
          <Typography
            className="dashboard-sub-title"
            sx={{ whiteSpace: "nowrap" }}
          >
            view and manage media file here
          </Typography>
          {folderName && (
            <Box className="breadcrumb-card">
              <span
                style={{ color: "#0065ff" }}
                onClick={() => setFolderName(false)}
              >
                Gallery
              </span>
              <img src="/images/chevron-right.svg" alt="arrow-right" />
              <span style={{ color: "#505F79" }}>{folderName}</span>
            </Box>
          )}
        </Box>
        {!upload && (
          <Box
            sx={{ display: "flex", flexFlow: "row", alignSelf: "flex-start" }}
          >
            <img
              src="/images/search_btn.svg"
              alt="search"
              style={{ cursor: "pointer" }}
            />
            <img
              src="/images/upload_btn.svg"
              alt="upload"
              // onClick={() => setOpen(true)}
              style={{ paddingLeft: "20px", cursor: "pointer" }}
            />
          </Box>
        )}
        {upload && <Box></Box>}
      </Box>
      {upload && (
        <Box className="gallery-upload-card">
          <span className="gallery-upload-title">No file found</span>
          <span className="gallery-upload-info">
            You have not upload media file yet.{" "}
          </span>
          <img
            src="/images/upload_btn.svg"
            alt="upload"
            onClick={() => setUpload(false)}
            style={{ paddingTop: "20px", cursor: "pointer" }}
          />
        </Box>
      )}
      {!upload && (
        <>
          {!folderName && (
            <Box sx={{ display: "flex", flexFlow: "row" }}>
              {data.map((item, index) => (
                <Box
                  className="gallery-folder-card"
                  key={index}
                  onClick={() => setFolderName(item.name)}
                >
                  <img src="/images/folder.svg" alt="folder" width="62px" />
                  <span className="gallery-image-title">{item.name}</span>
                  <span className="gallery-image-details">{item.fileInfo}</span>
                </Box>
              ))}
            </Box>
          )}
          {folderName && (
            <Box sx={{ display: "flex", flexFlow: "row" }}>
              {data.map((item, index) => (
                <Box className="gallery-view-card" key={index}>
                  <img src="/images/gallery-view.svg" alt="gallery" />
                </Box>
              ))}
            </Box>
          )}
        </>
      )}
      {/* <ApvFilePicker
        // apiKey={apikey}
        // options={options}
        onOpen={open}
        onClose={onClose}
        onCancel={onCancel}
        onUploadDone={onUploadDone}
        onUploadStarted={onUploadStart}
        onUploadError={onUploadError}
      /> */}
    </Box>
  );
}
