import * as React from "react";
import Box from "@mui/system/Box";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import SignSideCard from "../../components/SignSideCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchWorkSpaceList } from "../../utils/actions";

export default function WelcomeTeams() {
  const dispatch = useDispatch();
  const [workspaceList, setWorkspaceList] = React.useState([]);
  const { data, loading, error } = useSelector((state) => state.items);
  const email = localStorage.getItem("user");
  React.useEffect(() => {
    if (email) {
      dispatch(fetchWorkSpaceList(email));
    }
  }, [email]);

  React.useEffect(() => {
    if (data.workspaceList) {
      setWorkspaceList(data.workspaceList);
    }
  }, [data.workspaceList]);
  console.log(88, data.workspaceList);

  let navigate = useNavigate();

  const handleLaunch = (id, name) => {
    if (id) {
      localStorage.setItem("workspaceId", id);
      localStorage.setItem("workspaceUserName", name);
      navigate("/home");
    }
  };
  return (
    <Box className="sigin-card">
      <SignSideCard />
      <Box className="sigin-form-container">
        <span className="signin-title" style={{ marginTop: "80px" }}>
          Welcome back
        </span>
        <span className="signup-title-verify">
          Workspaces for vijay.chouhan490@gmail.com
        </span>
        <Box className="workspace-list-card">
          {workspaceList?.map((item, index) => (
            <Box className="signin-teams-card" ket={index}>
              <img src="/images/teams.svg" alt="team" />
              <Box
                sx={{ display: "flex", flexFlow: "column", marginLeft: "14px" }}
              >
                <span className="sign-in-teams-title">
                  {item.attributes.name}
                </span>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "16px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        alignSelf: "flex-start",
                      }}
                    >
                      <img src="/images/Avatar1.svg" alt="avatar-1" />
                      <img
                        src="/images/Avatar2.svg"
                        alt="avatar-2"
                        style={{ marginLeft: "-5px" }}
                      />
                      <img
                        src="/images/Avatar3.svg"
                        alt="avatar-3"
                        style={{ marginLeft: "-5px" }}
                      />
                    </Box>
                    <span className="sign-in-teams-memberes">
                      + 12 memberes
                    </span>
                  </Box>

                  <Button
                    variant="contained"
                    className="sign-in-teams-launch-btn"
                    onClick={() => handleLaunch(item.id, item.attributes.name)}
                    sx={{
                      background: "#0065FF",
                      marginTop: "16px",
                      padding: "4px 10px",
                      marginLeft: "100px",
                    }}
                  >
                    <span>Launch</span>
                  </Button>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
