import * as React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
export default function ProjectCard({
  handleEdit,
  projectData,
  handleClickEditOpen,
  handleDeleteDialogOpen,
}) {
  const CustomArrowIcon = () => {
    return <img src="images/MoreIcon.svg" alt="Selected Option" />;
  };

  const status = {
    inProcess: {
      bgcolor: "#fff8e7",
      colour: "#ffcf5c",
    },
    published: {
      bgcolor: "#E7EFE7",
      colour: "#00C48C",
    },
    onHold: {
      bgcolor: "#FFE7E7",
      colour: "#F05C5C",
    },
  };
  return (
    <Box sx={{ marginBottom: "20px" }}>
      {projectData?.map((item, index) => (
        <Card sx={{ minWidth: 275, marginTop: "21px" }} key={index}>
          <CardContent
            className="dashboard-project-card"
            sx={{ flexFlow: "column" }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              <Box sx={{ display: "flex" }} onClick={handleEdit}>
                <img src="images/YouTube.svg" alt="project-card" />
                <span className="dashboard-project-name">{item.Platform}</span>
                <span
                  className="dashboard-project-status"
                  style={{
                    color: status.inProcess.colour,
                    background: status.inProcess.bgcolor,
                  }}
                >
                  {item.status}
                </span>
              </Box>
              <Box>
                <Select
                  IconComponent={CustomArrowIcon}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                      outline: "none",
                    },
                    "& .MuiSelect-select": {
                      position: "absolute",
                    },
                  }}
                >
                  <MenuItem
                    value="edit"
                    className="dashboard-header-avatar"
                    onClick={() => handleClickEditOpen(index)}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    value="delete"
                    className="dashboard-header-avatar"
                    onClick={() => handleDeleteDialogOpen()}
                  >
                    Delete
                  </MenuItem>
                </Select>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "12px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "4px",
                  cursor: "pointer",
                }}
                onClick={handleEdit}
              >
                <span className="dashboard-project-details">{item.title}</span>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img src="/images/clock.svg" alt="clock" />
                <span className="dashboard-project-timer">
                  2 Days to publish
                </span>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
                justifyContent: "space-between",
              }}
            >
              <Typography
                className="dashboard-sub-title"
                sx={{ color: "#6F6F8D" }}
              >
                {item.description}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  alignSelf: "flex-start",
                }}
              >
                <img src="/images/Avatar1.svg" alt="clock" />
                <img
                  src="/images/Avatar2.svg"
                  alt="clock"
                  style={{ marginLeft: "-5px" }}
                />
                <img
                  src="/images/Avatar3.svg"
                  alt="clock"
                  style={{ marginLeft: "-5px" }}
                />
              </Box>
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
}
