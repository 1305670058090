import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/main.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import Dashboard from "./pages/Dashboard";
import SignUp from "./pages/signUp";
import SignIn from "./pages/signIn";
import SignupVerify from "./pages/signUp/SignupVerify";
import VerifyEmail from "./pages/signUp/VerifyEmail";
import UserDetails from "./pages/signUp/UserDetails";
import ContentType from "./pages/signUp/ContentType";
import EmailConfirm from "./pages/signUp/EmailConfirm";
import HomePage from "./pages/HomePage";
import SignInUserDetails from "./pages/signIn/SignInUserDetails";
import SignInVerifyEmail from "./pages/signIn/SignInVerifyEmail";
import WelcomeTeams from "./pages/signIn/WelcomeTeams";
import ForgotPassword from "./pages/signIn/ForgotPassword";
import SetNewPassword from "./pages/signIn/SetNewPassword";
import { Provider } from "react-redux";
import store from "./utils/store";
import AuthExample from "./api/auth/index";
import LoginRedirect from "./components/Auth/LoginRedirect";
const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/home",
    element: <Dashboard />,
  },
  {
    path: "/Dashboard",
    element: <Dashboard />,
  },
  {
    path: "/sign-in",
    element: <SignIn />,
  },
  {
    path: "/sign-up",
    element: <SignUp />,
  },
  {
    path: "/verify-email",
    element: <VerifyEmail />,
  },
  {
    path: "/sign-up-verify",
    element: <SignupVerify />,
  },
  {
    path: "/user-welcome",
    element: <UserDetails />,
  },
  {
    path: "/content-type",
    element: <ContentType />,
  },
  {
    path: "/email-confirm",
    element: <EmailConfirm />,
  },
  {
    path: "/sign-in-details",
    element: <SignInUserDetails />,
  },
  {
    path: "/sign-in-verify",
    element: <SignInVerifyEmail />,
  },
  {
    path: "/sign-in-welcome",
    element: <WelcomeTeams />,
  },
  {
    path: "/sign-in/forgot-pass",
    element: <ForgotPassword />,
  },
  {
    path: "/sign-in/new-pass",
    element: <SetNewPassword />,
  },
  {
    path: "/connect/:providerName/redirect",
    exact : true,
    element: <LoginRedirect />,
  },
  // {
  //   path: "/connect/auth0",
  //   exact : true,
  //   element: <AuthExample />,
  // },
]);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
