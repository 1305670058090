import * as React from "react";
import Box from "@mui/system/Box";
import { useNavigate } from "react-router-dom";
import { Button, Chip } from "@mui/material";
import SignSideCard from "../../components/SignSideCard";
import { InputBoxCard } from "../../components/InputBoxCard";

export default function ContentType() {
  let navigate = useNavigate();
  const handleBack = (event) => {
    navigate("/user-welcome");
  };
  const [chipData, setChipData] = React.useState([
    { name: "Blogger", status: true },
    { name: "Youtuber", status: false },
    { name: "Content Writer", status: true },
    { name: "Reel Creator", status: true },
    { name: "Podcast", status: true },
    { name: "Social media", status: true },
    { name: "Movie production", status: true },
  ]);

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate("/email-confirm");
    // Your form submission logic here
  };
  const updateStatus = (index) => {
    const updatedChipData = chipData.map((item, i) =>
      i === index ? { ...item, status: !item.status } : item
    );
    setChipData(updatedChipData);
  };
  return (
    <Box className="sigin-card">
      <SignSideCard />
      <Box className="sigin-form-container">
        <Button className="sign-in-btn" onClick={handleBack}>
          <img src="/images/arrow_back.svg" alt="back" />
          <span style={{ paddingLeft: "4px" }}>BACK</span>
        </Button>
        <form onSubmit={handleSubmit} style={{ paddingRight: "20%" }}>
          <div
            style={{ display: "flex", flexFlow: "row", alignItems: "center" }}
          >
            <div style={{ marginTop: "50px" }}>
              <span className="signin-title">
                What’s the the name of your platform or team.
              </span>
              <InputBoxCard
                label="Platform name"
                inputType="text"
                placeholder="Enter Platform Name"
                requiredState={true}
              />
            </div>
            <div>
              <img
                src="/images/e2wer.svg"
                alt="logo"
                style={{ paddingLeft: "26px" }}
              />
            </div>
          </div>
          <div style={{ marginTop: "20px", paddingRight: "20px" }}>
            <span className="signin-title">
              What’s the the name of your platform <br /> or team.
            </span>
          </div>
          <Box>
            {chipData.map((item, index) => (
              <Chip
                className="signup-chip"
                label={item.name}
                onClick={() => updateStatus(index)}
                style={{
                  marginTop: "20px",
                  marginRight: "10px",
                  border: "1px solid #dde2ee",
                  background: item.status ? "#0065FF" : "#f8f8f8",
                  color: item.status ? "#fff" : "#0065FF",
                }}
              />
            ))}
          </Box>
          <Button
            variant="contained"
            className="dashboard-create-btn"
            type="submit"
            sx={{ background: "#0065FF", marginTop: "30px" }}
          >
            <span>NEXT</span>
          </Button>
        </form>
      </Box>
    </Box>
  );
}
