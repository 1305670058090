import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { KeyboardArrowDown } from "@mui/icons-material";

const ImageWithDropdown = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  let navigate = useNavigate();
  const handleLogout = (event) => {
    navigate("/");
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div sx={{ margin: "0px 10px" }}>
      <Button
        aria-controls="dropdown-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <img
          src="/images/profile-avatar.png"
          alt="profile"
          style={{ width: "24px", height: "24px" }}
        />
        <KeyboardArrowDown sx={{ fill: "#505F79" }} />
      </Button>

      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} className="dashboard-header-avatar">
          Setting
        </MenuItem>
        <MenuItem onClick={handleLogout} className="dashboard-header-avatar">
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ImageWithDropdown;
