import * as React from "react";
import Box from "@mui/system/Box";
import { MenuItem, Select, Typography } from "@mui/material";
import ProjectCard from "../components/ProjectCard";
import CreateProjectDialog from "../components/CreateProjectDialog";
import DeleteProject from "../components/DeleteProject";
import ProjectEditor from "../components/ProjectEditor";

export default function MyContent() {
  const [showModal, setShowModal] = React.useState(false);
  const [selectedRole, setSelectedRole] = React.useState("Weekly");
  const [selectedOption, setSelectedOption] = React.useState("");
  const [dialogTitle, setDialogTitle] = React.useState("Create");
  const [title, setTitle] = React.useState("");
  const [plateform, setPlateform] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [projectDate, setProjectDate] = React.useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "role") {
      setSelectedRole(value);
    }
  };
  const handleEdit = () => {
    setOpen(true);
    setDialogTitle("Edit");
    setTitle("LOANS JALDI REPAY KARIYE! | Pay Off Debt Faster!");
    setPlateform("Youtube");
    setDescription(
      "How to repay loans quickly? How to reduce interest on loans? How does prepayment of loans work?"
    );
    setProjectDate("04-07-2023");
  };
  const handleOptionChange = (event) => {
    event.preventDefault();
    setSelectedOption(event.target.value);
  };
  const [open, setOpen] = React.useState(false);
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [showEditor, setShowEditor] = React.useState(true);
  const handleDeleteDialogOpen = () => {
    setDeleteDialog(true);
  };
  const handleEditor = () => {
    setShowEditor(false);
  };
  const CustomArrowIcon = () => {
    return <img src="images/chevron-down.svg" alt="Selected Option" />;
  };
  return showEditor ? (
    <Box
      className="dashboard-title-card"
      sx={{ marginLeft: "30px", marginBottom: "50px" }}
    >
      <Box
        sx={{
          display: "flex",
          flexFlow: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", flexFlow: "column" }}>
          <Typography className="dashboard-title">My Content</Typography>
          <Typography
            className="dashboard-sub-title"
            sx={{ whiteSpace: "nowrap" }}
          >
            you can start prepare content and track from here
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexFlow: "row", alignSelf: "flex-start" }}>
          <img
            src="/images/search_btn.svg"
            alt="search"
            style={{ cursor: "pointer" }}
          />
          <img
            src="/images/list-view.svg"
            alt="list"
            style={{ paddingLeft: "14px", cursor: "pointer" }}
          />
          <Select
            value={selectedRole}
            name="role"
            IconComponent={CustomArrowIcon}
            onChange={(e) => handleChange(e)}
            style={{
              height: "32px",
            }}
            sx={{
              borderRadius: "2px",
              border: "1px solid #CFD0D7",
              background: "#fdfdfd",
              marginLeft: "14px",
              width: "149px",
              paddingRight: "8px",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
                outline: "none",
              },
              "& .MuiSelect-select": {
                position: "absolute",
                color: "#17173a",
                fontFamily: '"Manrope", sans-serif',
                fontSize: "14px",
                width: "100%",
                fontWeight: "500",
              },
            }}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="Weekly">Weekly</MenuItem>
            <MenuItem value="Monthly">Monthly</MenuItem>
            <MenuItem value="Quarterly">Quarterly</MenuItem>
          </Select>
        </Box>
      </Box>
      <ProjectCard
        handleEditor={handleEditor}
        handleEdit={handleEdit}
        handleOptionChange={handleOptionChange}
        selectedOption={selectedOption}
        handleDeleteDialogOpen={handleDeleteDialogOpen}
      />
      <DeleteProject
        deleteDialog={deleteDialog}
        setDeleteDialog={setDeleteDialog}
      />
      <CreateProjectDialog
        dialogTitle={dialogTitle}
        title={title}
        description={description}
        plateform={plateform}
        projectDate={projectDate}
        setOpen={setOpen}
        open={open}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </Box>
  ) : (
    <ProjectEditor setShowEditor={setShowEditor} />
  );
}
