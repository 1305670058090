import * as React from "react";
import Stack from "@mui/system/Stack";
import { Box, Typography, Card, CardContent, Button } from "@mui/material";

import CreateProjectDialog from "../components/CreateProjectDialog";
import DeleteProject from "../components/DeleteProject";
import ProjectEditor from "../components/ProjectEditor";
import ProjectCard from "../components/ProjectCard";
import { useDispatch, useSelector } from "react-redux";
import {
  createProject,
  fetchProjectList,
  updateProjectDetail,
} from "../utils/actions";
import dayjs from "dayjs";
export default function CreateProject() {
  const [showModal, setShowModal] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState("Create");
  const [selectedOption, setSelectedOption] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [plateform, setPlateform] = React.useState("");
  const [description, setDescription] = React.useState("");
  const currentDate = new Date();
  const [projectDate, setProjectDate] = React.useState(dayjs(currentDate));
  const [projectData, setProjectData] = React.useState([]);
  const { data, loading, error } = useSelector((state) => state.items);
  const workspaceId = localStorage.getItem("workspaceId");
  const workspaceUserName = localStorage.getItem("workspaceUserName");
  React.useEffect(() => {
    dispatch(fetchProjectList(workspaceId));
  }, [workspaceId]);
  React.useEffect(() => {
    if (data.projectList > 0) {
      setProjectData(data.projectList);
      setShowModal(true);
    }
  }, [data.projectList]);
  console.log(22, title);
  const handleOptionChange = (event) => {
    event.preventDefault();
    setSelectedOption(event.target.value);
  };
  const [open, setOpen] = React.useState(false);
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [showEditor, setShowEditor] = React.useState(true);
  const dispatch = useDispatch();
  const handleDeleteDialogOpen = () => {
    setDeleteDialog(true);
  };
  console.log(99, showEditor);
  const convertToSlug = (name) => {
    // Convert to lowercase and replace spaces with hyphens
    const slug = `${name.toLowerCase().replace(/\s+/g, "-")}-slug`;
    return slug;
  };

  React.useEffect(() => {
    if (data.projectList) {
      setProjectData([data.projectList.data.attributes]);
    }
  }, [data.projectList]);
  console.log(55, projectData);
  const handleClickEditOpen = (id) => {
    setOpen(true);
    setDialogTitle("Edit");
    projectData.map((item, index) => {
      console.log(item);
      if (index === id) {
        setTitle(item.title);
        setDescription(item.description);
        setPlateform(item.Platform);
        setProjectDate(dayjs(item.publish_date));
      }
    });
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleEdit = () => {
    setShowEditor(!showEditor);
  };
  const handleSave = () => {
    const slug = convertToSlug(title);
    {
      if (workspaceId) {
        const createData = {
          data: {
            title: title,
            description: description,
            Platform: plateform,
            publish_date: projectDate,
            slug: slug,
            workspace: workspaceId,
            status: "Not Started",
            data: [],
          },
        };
        if (createData) {
          if (dialogTitle === "Create") {
            dispatch(createProject(createData));
          } else if (dialogTitle === "Edit") {
          }
          dispatch(updateProjectDetail(workspaceId, createData));
        }
      }
    }
  };
  const CreateCard = () => {
    return (
      <Stack
        direction={{ xs: "column", sm: "column", lg: "column" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Box className="dashboard-title-card">
          <Typography className="dashboard-title">
            {`Good Morning, ${workspaceUserName} 👋`}
          </Typography>
          <Typography className="dashboard-sub-title">
            Have a nice day today
          </Typography>
        </Box>
        <Box className="dashboard-create-card">
          <Box className="dashboard-create-title">
            <img src="/images/create_project.svg" alt="create" />
            <Typography className="dashboard-title">
              You have not planned any content yet. Create content now
            </Typography>
            <Typography
              className="dashboard-sub-title"
              sx={{ marginTop: "10px", textAlign: "center" }}
            >
              Content creators like youtubers, podcast, blogger, Movie or short
              film and social media using multiple tool to manage content across
              platforms.
            </Typography>
            <Button
              variant="contained"
              className="dashboard-create-btn"
              sx={{ background: "#0065FF", marginTop: "30px" }}
              startIcon={<img alt="create" src="images/plus-square.svg" />}
              onClick={() => handleClickOpen()}
            >
              <span>CREATE</span>
            </Button>
          </Box>
        </Box>
      </Stack>
    );
  };

  const DashboardCard = (props) => {
    return showEditor ? (
      <Box>
        <Box className="dashboard-title-card">
          <Typography className="dashboard-title">
            Good Morning, ${workspaceUserName} 👋
          </Typography>
          <Typography className="dashboard-sub-title">
            Have a nice day today
          </Typography>
        </Box>
        <Card sx={{ minWidth: 275, marginTop: "21px" }}>
          <CardContent className="dashboard-project-card">
            <Box
              className="dashboard-project-detail-card"
              sx={{
                borderRight: "1px solid var(--almost-grey, #dde2ee)",
                paddingRight: "80px",
              }}
            >
              <span className="dashboard-project-detail">Total Projects</span>
              <span className="dashboard-project-sub-detail">10</span>
            </Box>
            <Box className="dashboard-project-detail-card">
              <span className="dashboard-project-detail">Not started</span>
              <span className="dashboard-project-sub-detail">02</span>
            </Box>
            <Box className="dashboard-project-detail-card">
              <span className="dashboard-project-detail">In progress</span>
              <span className="dashboard-project-sub-detail">04</span>
            </Box>
            <Box className="dashboard-project-detail-card">
              <span className="dashboard-project-detail">Published</span>
              <span className="dashboard-project-sub-detail">02</span>
            </Box>
            <Box className="dashboard-project-detail-card">
              <span className="dashboard-project-detail">On hold</span>
              <span className="dashboard-project-sub-detail">02</span>
            </Box>
          </CardContent>
        </Card>
        <Box className="dashboard-title-card" sx={{ marginTop: "30px" }}>
          <Typography className="dashboard-title">
            Upcoming week plan
          </Typography>
          <Typography className="dashboard-sub-title">
            you can start prepare content and track from here
          </Typography>
        </Box>
        <ProjectCard
          handleEdit={handleEdit}
          projectData={projectData}
          handleOptionChange={handleOptionChange}
          selectedOption={selectedOption}
          handleClickEditOpen={handleClickEditOpen}
          handleDeleteDialogOpen={handleDeleteDialogOpen}
        />
      </Box>
    ) : (
      <ProjectEditor setShowEditor={setShowEditor} />
    );
  };
  return (
    <div style={{ marginLeft: "30px" }}>
      {showModal ? <CreateCard /> : <DashboardCard />}
      <DeleteProject
        deleteDialog={deleteDialog}
        setDeleteDialog={setDeleteDialog}
      />
      <CreateProjectDialog
        dialogTitle={dialogTitle}
        title={title}
        setTitle={setTitle}
        handleSave={handleSave}
        description={description}
        setDescription={setDescription}
        plateform={plateform}
        setPlateform={setPlateform}
        setOpen={setOpen}
        setProjectDate={setProjectDate}
        projectDate={projectDate}
        open={open}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </div>
  );
}
