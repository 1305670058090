import React, { useRef, useState, useEffect } from "react";
// import { Button } from "@mui/material";
import { Player, ControlBar } from "video-react";

const sources = {
  sintelTrailer: "http://media.w3.org/2010/05/sintel/trailer.mp4",
  bunnyTrailer: "http://media.w3.org/2010/05/bunny/trailer.mp4",
  bunnyMovie: "http://media.w3.org/2010/05/bunny/movie.mp4",
  test: "http://media.w3.org/2010/05/video/movie_300.webm",
};

const VideoPlayer = () => {
  const playerRef = useRef(null);
  const [source, setSource] = useState(sources.sintelTrailer);
  const [playerState, setPlayerState] = useState(null);

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.subscribeToStateChange((state) => {
        setPlayerState(state);
      });
    }
  }, []);

  // const play = () => {
  //   if (playerRef.current) {
  //     playerRef.current.play();
  //   }
  // };

  // const pause = () => {
  //   if (playerRef.current) {
  //     playerRef.current.pause();
  //   }
  // };

  // const load = () => {
  //   if (playerRef.current) {
  //     playerRef.current.load();
  //   }
  // };

  // const changeCurrentTime = (seconds) => () => {
  //   if (playerRef.current) {
  //     const { currentTime } = playerRef.current.getState().player;
  //     playerRef.current.seek(currentTime + seconds);
  //   }
  // };

  // const seek = (seconds) => () => {
  //   if (playerRef.current) {
  //     playerRef.current.seek(seconds);
  //   }
  // };

  // const changePlaybackRateRate = (steps) => () => {
  //   if (playerRef.current) {
  //     const { playbackRate } = playerRef.current.getState().player;
  //     playerRef.current.playbackRate = playbackRate + steps;
  //   }
  // };

  // const changeVolume = (steps) => () => {
  //   if (playerRef.current) {
  //     const { volume } = playerRef.current.getState().player;
  //     playerRef.current.volume = volume + steps;
  //   }
  // };

  // const setMuted = (muted) => () => {
  //   if (playerRef.current) {
  //     playerRef.current.muted = muted;
  //   }
  // };

  // const changeSource = (name) => () => {
  //   setSource(sources[name]);
  //   if (playerRef.current) {
  //     playerRef.current.load();
  //   }
  // };

  return (
    <div>
      <Player ref={playerRef}>
        <source src={source} />
        <ControlBar autoHide={true} />
      </Player>
      {/* <div className="py-3">
        <Button onClick={play} className="mr-3">
          play()
        </Button>
        <Button onClick={pause} className="mr-3">
          pause()
        </Button>
        <Button onClick={load} className="mr-3">
          load()
        </Button>
      </div>
      <div className="pb-3">
        <Button onClick={changeCurrentTime(10)} className="mr-3">
          currentTime += 10
        </Button>
        <Button onClick={changeCurrentTime(-10)} className="mr-3">
          currentTime -= 10
        </Button>
        <Button onClick={seek(50)} className="mr-3">
          currentTime = 50
        </Button>
      </div>
      <div className="pb-3">
        <Button onClick={changePlaybackRateRate(1)} className="mr-3">
          playbackRate++
        </Button>
        <Button onClick={changePlaybackRateRate(-1)} className="mr-3">
          playbackRate--
        </Button>
        <Button onClick={changePlaybackRateRate(0.1)} className="mr-3">
          playbackRate+=0.1
        </Button>
        <Button onClick={changePlaybackRateRate(-0.1)} className="mr-3">
          playbackRate-=0.1
        </Button>
      </div> */}
      {/* <div className="pb-3">
        <Button onClick={changeVolume(0.1)} className="mr-3">
          volume+=0.1
        </Button>
        <Button onClick={changeVolume(-0.1)} className="mr-3">
          volume-=0.1
        </Button>
        <Button onClick={setMuted(true)} className="mr-3">
          muted=true
        </Button>
        <Button onClick={setMuted(false)} className="mr-3">
          muted=false
        </Button>
      </div> */}
      {/* <div className="pb-3">
        <Button onClick={changeSource("sintelTrailer")} className="mr-3">
          Sintel teaser
        </Button>
        <Button onClick={changeSource("bunnyTrailer")} className="mr-3">
          Bunny trailer
        </Button>
        <Button onClick={changeSource("bunnyMovie")} className="mr-3">
          Bunny movie
        </Button>
        <Button onClick={changeSource("test")} className="mr-3">
          Test movie
        </Button>
      </div> */}
      {/* <pre>{JSON.stringify(playerState, null, 2)}</pre> */}
    </div>
  );
};

export default VideoPlayer;
