import React, { useState } from "react";

const CommonFilter = ({ filterType, options, onFilterChange }) => {
  const [filterValue, setFilterValue] = useState("");

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setFilterValue(newValue);
    onFilterChange({ type: filterType, value: newValue });
  };

  const renderFilterInput = () => {
    switch (filterType) {
      case "dropdown":
        return (
          <select value={filterValue} onChange={handleInputChange}>
            <option value="">Select Option</option>
            {options?.map((option) => (
              <option key={option.label} value={option.label}>
                {option.label}
              </option>
            ))}
          </select>
        );
      case "dateRange":
        return (
          <>
            <input
              type="date"
              placeholder="Start Date"
              value={filterValue}
              onChange={handleInputChange}
            />
            <input
              type="date"
              placeholder="End Date"
              value={filterValue}
              onChange={handleInputChange}
            />
          </>
        );
      default:
        return (
          <input
            type="text"
            placeholder="Filter..."
            value={filterValue}
            onChange={handleInputChange}
          />
        );
    }
  };

  return <div>{renderFilterInput()}</div>;
};

export default CommonFilter;
