import * as React from "react";
import Box from "@mui/system/Box";
export default function SignSideCard() {
  return (
    <Box className="sigin-container">
      <img
        src="images/sigin_costan.svg"
        alt="costan"
        style={{ width: "114px", marginTop: "40px" }}
      />
      <span className="sigin-title">
        You're one step away from simply content planning.
      </span>
      <img
        src="/images/sigin-group.svg"
        alt="group"
        style={{ width: "328px", marginLeft: "-2px" }}
      />
      <img
        src="images/user-comment.svg"
        alt="costan"
        style={{ width: "413px", marginTop: "100px" }}
      />
    </Box>
  );
}
