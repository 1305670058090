import * as React from "react";
import Box from "@mui/system/Box";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import SignSideCard from "../../components/SignSideCard";
import {
  InputBoxCard,
  InputBoxPasswordCard,
} from "../../components/InputBoxCard";

export default function UserDetails() {
  let navigate = useNavigate();
  const handleSubmit = (event) => {
    event.preventDefault();
    navigate("/content-type");
    // Your form submission logic here
  };
  return (
    <Box className="sigin-card">
      <SignSideCard />
      <Box className="sigin-form-container">
        <span className="signin-title" style={{ marginTop: "50px" }}>
          Welcome to Costan!
        </span>
        <span className="signup-title-verify">
          You’re signing up as Ankur123@warikoo.com
        </span>
        <form
          onSubmit={handleSubmit}
          style={{ width: "75%", marginTop: "10px" }}
        >
          <InputBoxCard
            label="Full name"
            requiredState={true}
            inputType="text"
            placeholder="Enter Your Name"
          />
          <InputBoxCard
            label="WhatsApp mobile no"
            inputType="number"
            showStar="none"
            requiredState={false}
            placeholder="Enter whatsapp mobile no."
          />
          <InputBoxPasswordCard
            requiredState={true}
            label="Password"
            placeholder="Enter Password"
          />
          <InputBoxPasswordCard
            label="Confirm Password"
            requiredState={true}
            placeholder="Enter Confirm Password"
          />
          <Button
            variant="contained"
            className="dashboard-create-btn"
            type="submit"
            sx={{ background: "#0065FF", marginTop: "30px" }}
          >
            <span>Next</span>
          </Button>
        </form>
      </Box>
    </Box>
  );
}
