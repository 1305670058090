import * as React from "react";
import Box from "@mui/system/Box";
import {
  Button,
  Card,
  CardContent,
  Chip,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { InputBoxCard } from "../components/InputBoxCard";
import EditPlateformDetails from "../components/EditPlateformDetails";
import DeleteAccount from "../components/DeleteAccount";
import EditProfile from "../components/EditProfile";
import ManagePlan from "../components/ManagePlan";
function createData(name, last_activity, role, remove) {
  return { name, last_activity, role, remove };
}

const rows = [
  createData("Ankur Warikoo", "27 Dec, 2021", "Creator", "Remove"),
  createData("Neha Sharma", "24 Dec, 2022", "Youtuber", "Remove"),
  createData("Ankur Warikoo", "26 Dec, 2021", "Writer", "Remove"),
  createData("Neha Sharma", "22 Dec, 2021", "Creator", "Remove"),
  createData("Ankur Warikoo", "21 Dec, 2021", "Blogger", "Remove"),
];
export default function Setting() {
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [selectedEmail, setSelectedEmail] = React.useState("");
  const [selectedRole, setSelectedRole] = React.useState("");
  const [editName, setEditName] = React.useState("");
  const [editRole, setEditRole] = React.useState("");
  const [whatsappNo, setWhatsappNo] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [openManagePlan, setOpenManagePlan] = React.useState(false);
  const [openEditProfile, setOpenEditProfile] = React.useState(false);
  const openEditPlateform = () => {
    setOpen(true);
  };
  const handleManageDialogOpen = () => {
    setOpenManagePlan(true);
  };
  const handleDeleteDialogOpen = () => {
    setDeleteDialog(true);
  };
  const handleEditProfileOpen = () => {
    setOpenEditProfile(true);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setSelectedEmail(value);
    } else if (name === "role") {
      setSelectedRole(value);
    }
    console.log(selectedEmail, selectedRole);
  };
  const [chipData, setChipData] = React.useState([
    { name: "Blogger", status: true },
    { name: "Youtuber", status: false },
    { name: "Reel Creator", status: true },
  ]);

  return (
    <Box
      className="dashboard-title-card"
      sx={{ marginLeft: "30px", marginBottom: "50px" }}
    >
      <Typography className="dashboard-title">Settings</Typography>
      <Typography className="dashboard-sub-title">
        view and manage platform
      </Typography>
      <Card sx={{ minWidth: 275, marginTop: "21px" }}>
        <CardContent className="setting-plateform-card">
          <Box>
            <Box sx={{ padding: "20px 14px" }}>
              <span className="setting-plateform-title">Platform name :</span>
              <span className="setting-plateform-sub-title">warikoo_team</span>
            </Box>
            <Box sx={{ padding: "10px 14px" }}>
              <span className="setting-plateform-title">About: </span>
              <span className="setting-plateform-sub-title">
                Influencer and Motivational Speaker Content{" "}
              </span>
            </Box>
            <Box>
              {chipData.map((item, index) => (
                <Chip
                  className="signup-chip"
                  label={item.name}
                  style={{
                    marginTop: "20px",
                    marginRight: "10px",
                    border: "1px solid #dde2ee",
                    background: "#DEEBFF",
                    color: "#0065FF",
                  }}
                />
              ))}
            </Box>
          </Box>
          <Box className="setting-profile-box">
            <Box className="setting-profile">
              <img src="/images/profileimage.png" alt="profile" />
            </Box>
            <Button
              sx={{ height: "fit-content", minWidth: "fit-content" }}
              onClick={openEditPlateform}
            >
              <img src="/images/EditButton.svg" alt="edit-button" />
            </Button>
          </Box>
        </CardContent>
      </Card>
      <Typography className="dashboard-title" sx={{ marginTop: "28px" }}>
        Profile
      </Typography>
      <Card sx={{ minWidth: 275, marginTop: "10px" }}>
        <CardContent className="setting-plateform-card">
          <Box>
            <Box
              sx={{
                display: "flex",
                flexFlow: "row",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ padding: "20px 14px" }}>
                <span className="setting-plateform-title">Name :</span>
                <span className="setting-plateform-sub-title">
                  Ankur Warikoo
                </span>
              </Box>
              <Box sx={{ padding: "20px 14px" }}>
                <span className="setting-plateform-title">Role :</span>
                <span className="setting-plateform-sub-title">Owner</span>
              </Box>
            </Box>
            <Box sx={{ padding: "20px 14px" }}>
              <span className="setting-plateform-title">
                WhatsApp Mobile no :
              </span>
              <span className="setting-plateform-sub-title">
                +91 - 1234567890
              </span>
            </Box>
          </Box>
          <Button
            sx={{ height: "fit-content", minWidth: "fit-content" }}
            onClick={handleEditProfileOpen}
          >
            <img src="/images/EditButton.svg" alt="edit-button" />
          </Button>
        </CardContent>
      </Card>
      <Typography className="dashboard-title" sx={{ marginTop: "28px" }}>
        Temmates Members (01)
      </Typography>
      <Card sx={{ minWidth: 275, marginTop: "10px", background: "white" }}>
        <CardContent
          className="setting-plateform-card"
          sx={{ flexFlow: "column" }}
        >
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow
                  sx={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}
                >
                  <TableCell className="setting-tabe-team-title">
                    Name
                  </TableCell>
                  <TableCell className="setting-tabe-team-title" align="right">
                    Last activity
                  </TableCell>
                  <TableCell className="setting-tabe-team-title" align="right">
                    Role
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <Box className="setting-teams">
                        <img
                          src="/images/profileimage.png"
                          alt="profile"
                          style={{ width: "38px", height: "38px" }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexFlow: "column",
                            marginLeft: "10px",
                          }}
                        >
                          <span className="setting-team-title">{row.name}</span>
                          <span className="setting-team-mail">
                            Ankur1233@warikoo.com
                          </span>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell align="right">{row.last_activity}</TableCell>
                    <TableCell align="right">{row.role}</TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        textTransform: "uppercase",
                        color: "#0065FF",
                        cursor: "pointer",
                        fontWeight: "600",
                        fontFamily: "'Manrope', sans-serif",
                      }}
                    >
                      {row.remove}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box className="setting-invite-user">
            <div style={{ width: "40%" }}>
              <InputBoxCard
                label="Email Id"
                inputType="email"
                showStar="none"
                name="email"
                placeholder="Eg. Neha@gmail.com"
                requiredState={false}
              />
            </div>
            <div
              className="create-project-container"
              style={{ width: "40%", marginTop: "2px" }}
            >
              <span
                className="create-project-title"
                style={{ marginBottom: "8px" }}
              >
                Role
              </span>
              <Select
                value={selectedRole}
                name="role"
                onChange={(e) => handleChange(e)}
                style={{
                  height: "44px",
                }}
                sx={{
                  "& .MuiSelect-select": {
                    color: "#17173a",
                    fontFamily: '"Manrope", sans-serif',
                    fontSize: "14px",
                    fontWeight: "500",
                  },
                }}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="Youtube">Youtube</MenuItem>
                <MenuItem value="Linkedin">Linkedin</MenuItem>
                <MenuItem value="Instagram">Instagram</MenuItem>
              </Select>
            </div>
            <div style={{ marginTop: "30px", marginRight: "10px" }}>
              <Button className="create-project-dialog-btn">Invite</Button>
            </div>
          </Box>
        </CardContent>
      </Card>
      <Typography className="dashboard-title" sx={{ marginTop: "28px" }}>
        Plan{" "}
        <Chip
          className="signup-chip"
          label="FREE TRIAL"
          style={{
            marginLeft: "6px",
            border: "1px solid #dde2ee",
            background: "#DEEBFF",
            color: "#0065FF",
          }}
        />
      </Typography>
      <Card sx={{ minWidth: 275, marginTop: "14px" }}>
        <CardContent className="setting-plateform-card">
          <Box>
            <Box sx={{ padding: "20px 14px" }}>
              <span className="setting-plateform-title">Seat Usage :</span>
              <span className="setting-plateform-sub-title">1 of 2 Users</span>
            </Box>
            <Box sx={{ padding: "20px 14px" }}>
              <span className="setting-plateform-title">Storage Usage :</span>
              <span className="setting-plateform-sub-title">3.12MB of 1GB</span>
            </Box>
            <Box sx={{ padding: "20px 14px" }}>
              <span className="setting-plateform-title">Project :</span>
              <span className="setting-plateform-sub-title">
                1 of 10 Project
              </span>
            </Box>
          </Box>
          <div style={{ alignSelf: "flex-end", marginRight: "10px" }}>
            <Button
              className="create-project-dialog-btn"
              onClick={handleManageDialogOpen}
            >
              Manage Plan
            </Button>
          </div>
        </CardContent>
      </Card>
      <Card sx={{ minWidth: 275, marginTop: "26px" }}>
        <CardContent className="setting-plateform-card">
          <Box>
            <Box
              sx={{
                display: "flex",
                flexFlow: "column",
              }}
            >
              <Typography className="dashboard-title">Deactivation</Typography>
              <Typography
                className="dashboard-sub-title"
                sx={{ whiteSpace: "nowrap" }}
              >
                Remove access to platform in Costan
              </Typography>
            </Box>
          </Box>
          <div style={{ alignSelf: "flex-end", marginRight: "10px" }}>
            <Button
              className="delete-project-dialog-btn"
              onClick={handleDeleteDialogOpen}
            >
              DEACTIVATE ACCOUNT
            </Button>
          </div>
        </CardContent>
      </Card>
      <EditPlateformDetails
        plateform=""
        setOpen={setOpen}
        open={open}
        chipData={chipData}
        setChipData={setChipData}
      />
      <DeleteAccount
        deleteDialog={deleteDialog}
        setDeleteDialog={setDeleteDialog}
      />
      <EditProfile
        openEditProfile={openEditProfile}
        setOpenEditProfile={setOpenEditProfile}
        editName={editName}
        editRole={editRole}
        whatsappNo={whatsappNo}
        setEditName={setEditName}
        setEditRole={setEditRole}
        setWhatsappNo={setWhatsappNo}
      />
      <ManagePlan
        openManagePlan={openManagePlan}
        setOpenManagePlan={setOpenManagePlan}
      />
    </Box>
  );
}
