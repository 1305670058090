import * as React from "react";
import Box from "@mui/system/Box";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function VerifyEmail() {
  let navigate = useNavigate();
  const handleNext = () => {
    navigate("/user-welcome");
  };
  return (
    <Box className="verify-email-container">
      <Box className="verify-email-card">
        <img
          src="images/sigin_costan.svg"
          alt="costan"
          style={{ width: "114px" }}
        />
        <span className="signin-terms">You're almost there!</span>
        <span className="signup-title-verify">
          We sent an verification link. Please verify your email
        </span>
        <Button
          variant="contained"
          className="dashboard-create-btn"
          onClick={handleNext}
          sx={{ background: "#0065FF", marginTop: "30px" }}
        >
          <span>Verify email address</span>
        </Button>
      </Box>
    </Box>
  );
}
