import * as React from "react";
import Box from "@mui/system/Box";
import { Typography } from "@mui/material";
import CommonFilter from "./CommonFilter";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "../utils/actions";

export default function Chart() {
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.items);
  const [intial, setIntial] = React.useState([
    { name: "country", label: "India", data: [] },
  ]);
  const ro = [
    {
      label: "India",
      id: 20,
      name: "India",
    },
    {
      label: "Af",
      id: 201,
      name: "AF",
    },
    {
      label: "Re",
      id: 10,
      name: "Re",
    },
  ];
  const [newCount, setNewCount] = React.useState("");

  React.useEffect((e) => {
    dispatch(fetchUser("nitin@gmail.com"));
    if (data) {
      setNewCount(ro);
    }
  }, []);
  React.useEffect(() => {
    const updatedInitial = intial?.map((item) => {
      if (item.name === "country") {
        return { ...item, data: newCount }; // Update the 'data' property
      }
      return item; // Keep other items unchanged
    });

    // You can set the updated array back to 'intial' if needed
    // intial = updatedInitial;

    setIntial(updatedInitial);
  }, [newCount]);
  const [filterData, setFilterData] = React.useState({});
  const handleFilterChange = (filter) => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      [filter.type]: filter.value,
    }));
  };

  console.log(12, intial);
  console.log(23, newCount);
  return (
    <Box sx={{ display: "flex" }}>
      <Typography>Good Morning, warikoo_team 👋</Typography>
      <div>
        {intial.map((item) => (
          // console.log(90, item.data)
          <CommonFilter
            filterType="dropdown"
            options={item.data}
            onFilterChange={handleFilterChange}
          />
        ))}
        <CommonFilter filterType="input" onFilterChange={handleFilterChange} />
        <CommonFilter
          filterType="dateRange"
          onFilterChange={handleFilterChange}
        />
        {/* Use the filterData state to filter your data */}
      </div>
    </Box>
  );
}
