import React, { useEffect, useState } from "react";
import { TextField, InputAdornment } from "@mui/material";
import IconButton from "@mui/material/IconButton";

function InputBoxCard({
  label,
  inputType,
  placeholder,
  showStar,
  requiredState,
  handleChange,
  name,
  defaultValue,
}) {
  return (
    <div className="signin-project-container">
      <span className="signin-project-title">
        {label}
        <span style={{ display: showStar, color: "#f05c5c" }}>*</span>
      </span>
      <TextField
        className="signin-project-title-input"
        required={requiredState}
        onChange={handleChange}
        defaultValue={defaultValue}
        autocomplete="off"
        name={name}
        InputProps={{
          disableUnderline: true,
        }}
        variant="standard"
        sx={{
          m: "4px",
          ml: 0,
          mt: "6px",
        }}
        type={inputType}
        placeholder={placeholder}
      />
    </div>
  );
}

function InputBoxPasswordCard({
  label,
  placeholder,
  showStar,
  requiredState,
  handleChange,
  name,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="signin-project-container">
      <span className="signin-project-title">
        {label}
        <span style={{ display: showStar, color: "#f05c5c" }}>*</span>
      </span>
      <TextField
        placeholder={placeholder}
        type={showPassword ? "text" : "password"}
        variant="standard"
        name={name}
        onChange={handleChange}
        sx={{
          m: 1,
          ml: 0,
          mt: 1,
        }}
        className="signin-project-title-input"
        required={requiredState}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end" sx={{ paddingRight: "8px" }}>
              <IconButton onClick={handleTogglePassword} edge="end">
                {showPassword ? (
                  <img src="/images/show_pass.svg" alt="show-pass" />
                ) : (
                  <img src="/images/hide_pass.svg" alt="hide-pass" />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}
export { InputBoxCard, InputBoxPasswordCard };
