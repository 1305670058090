import * as React from "react";
import Box from "@mui/system/Box";

export default function ChatUs() {
  return (
    <Box sx={{ display: "flex", flexFlow: "column" }}>
      <Box sx={{ display: "flex", flexFlow: "row" }}>
        <img
          src="images/chat-title.svg"
          alt="chat-title"
          style={{ width: "28px" }}
        />
        <span className="chat-title">Chat</span>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexFlow: "column",
          alignItems: "center",
          marginTop: "160px",
        }}
      >
        <img src="images/chat.svg" alt="chat" style={{ width: "127px" }} />
        <span className="chat-comming-soon">
          Exciting things are on the horizon! <br />
          Chat with teammates is coming soon, so stay tuned!
        </span>
      </Box>
    </Box>
  );
}
