import * as React from "react";
import Box from "@mui/system/Box";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import SignSideCard from "../../components/SignSideCard";
import { InputBoxCard } from "../../components/InputBoxCard";

export default function ForgotPassword() {
  let navigate = useNavigate();
  const handleSignin = (event) => {
    navigate("/sign-in");
  };
  const handleBack = () => {
    navigate("/sign-in-details");
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    navigate("/sign-in/new-pass");
    // Your form submission logic here
  };
  return (
    <Box className="sigin-card">
      <SignSideCard />

      <Box className="sigin-form-container">
        <Button className="sign-in-btn" onClick={handleBack}>
          <img src="/images/arrow_back.svg" alt="back" />
          <span style={{ paddingLeft: "4px" }}>BACK</span>
        </Button>
        <span className="signin-title" style={{ marginTop: "100px" }}>
          Forgot your password
        </span>
        <form
          onSubmit={handleSubmit}
          style={{ width: "75%", marginTop: "10px" }}
        >
          <InputBoxCard
            label="Email address"
            requiredState={true}
            inputType="mail"
            placeholder="Enter Your Email"
          />
          <Button
            variant="contained"
            className="dashboard-create-btn"
            type="submit"
            sx={{ background: "#0065FF", marginTop: "30px" }}
          >
            <span>RESET</span>
          </Button>
        </form>
        <span className="signin-terms">
          Remember password
          <span
            style={{ color: "#0065FF", cursor: "pointer", marginLeft: "8px" }}
            onClick={handleSignin}
          >
            Sign In
          </span>
        </span>
      </Box>
    </Box>
  );
}
