import {
  fetchItemsStart,
  fetchItemsSuccess,
  fetchItemsFailure,
} from "./apiSlice";
import { GET_ITEMS, Get_WorkSpace_List } from "./graphql"; // Import your GraphQL query
import axios from "axios";

const authToken =
  "4692a67c83ad1336d314c2e965976191d6e7cc3fe6c31fcdf2dea51f869fc60bf9b54467e073b5ec8b449246aa7b9364956979d52493188b17f28a0ddbef5d583fc78126983d270bd29ee46c38e629b55f5112f5b489b40c6963a8036e2a6cee5ce08688f197cf136fa51d89f228ebdf30cf5aa7d5adbae4f72afb73fa9b283b";

const BASE_URL = "http://54.145.103.56:1337"; // Your base API URL

export const fetchUser = (email) => async (dispatch) => {
  try {
    dispatch(fetchItemsStart());
    const response = await axios.post(
      `${BASE_URL}/graphql`,
      {
        query: GET_ITEMS(email),
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`, // Pass the authentication token
          "Content-Type": "application/json",
        },
      }
    );
    const user =
      response.data.data.usersPermissionsUsers.data[0].attributes.email;
    if (user) {
      dispatch(fetchItemsSuccess({ key: "email", value: user }));
    }
  } catch (error) {
    dispatch(fetchItemsFailure(error.message));
  }
};

export const loginUser = (userData) => async (dispatch) => {
  try {
    dispatch(fetchItemsStart());

    const response = await axios.post(`${BASE_URL}/api/auth/local`, userData, {
      headers: {
        Authorization: `Bearer ${authToken}`, // Pass the authentication token
        "Content-Type": "application/json",
      },
    });

    dispatch(
      fetchItemsSuccess({ key: "userLogin", value: response.data.user })
    );
  } catch (error) {
    dispatch(fetchItemsFailure(error.message));
  }
};
export const fetchWorkSpaceList = (email) => async (dispatch) => {
  try {
    console.log(email);
    dispatch(fetchItemsStart());
    const response = await axios.post(
      `${BASE_URL}/graphql`,
      {
        query: Get_WorkSpace_List(email),
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`, // Pass the authentication token
          "Content-Type": "application/json",
        },
      }
    );
    dispatch(
      fetchItemsSuccess({
        key: "workspaceList",
        value: response.data.data.platforms.data[0].attributes.workspaces.data,
      })
    );
  } catch (error) {
    dispatch(fetchItemsFailure(error.message));
  }
};
export const createProject = (userData) => async (dispatch) => {
  try {
    dispatch(fetchItemsStart());

    const response = await axios.post(`${BASE_URL}/api/projects`, userData, {
      headers: {
        Authorization: `Bearer ${authToken}`, // Pass the authentication token
        "Content-Type": "application/json",
      },
    });

    dispatch(
      fetchItemsSuccess({
        key: "projectList",
        value: response.data,
      })
    );
  } catch (error) {
    dispatch(fetchItemsFailure(error.message));
  }
};
export const fetchProjectList = (id) => async (dispatch) => {
  try {
    dispatch(fetchItemsStart());

    const response = await axios.get(`${BASE_URL}/api/projects/${id}`, {
      headers: {
        Authorization: `Bearer ${authToken}`, // Pass the authentication token
        "Content-Type": "application/json",
      },
    });

    dispatch(
      fetchItemsSuccess({
        key: "projectList",
        value: response.data,
      })
    );
  } catch (error) {
    dispatch(fetchItemsFailure(error.message));
  }
};
export const updateProjectDetail = (id, data) => async (dispatch) => {
  try {
    dispatch(fetchItemsStart());

    const response = await axios.put(`${BASE_URL}/api/projects/${id}`, data, {
      headers: {
        Authorization: `Bearer ${authToken}`, // Pass the authentication token
        "Content-Type": "application/json",
      },
    });

    dispatch(
      fetchItemsSuccess({
        key: "projectList",
        value: response.data,
      })
    );
  } catch (error) {
    dispatch(fetchItemsFailure(error.message));
  }
};
