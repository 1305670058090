import * as React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from "@mui/material";
export default function DeleteProject({ setDeleteDialog, deleteDialog }) {
  const handleClose = () => {
    setDeleteDialog(false);
  };
  const handleSubmit = (event) => {
    handleClose();
  };
  return (
    <Dialog
      open={deleteDialog}
      onClose={() => handleClose()}
      sx={{
        "& .MuiPaper-elevation": {
          width: "677px",
          p: "10px",
          pb: "36px",
          pt: "36px",
          display: "flex",
          flexFlow: "column",
          alignItems: "center",
        },
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        className="create-project-dialog"
        id="alert-dialog-title"
        sx={{ paddingBottom: "4px" }}
      >
        Are you sure you want to delete project?
      </DialogTitle>
      <DialogContentText
        className="create-project-sub-dialog"
        sx={{ paddingBottom: "40px" }}
        id="alert-dialog-description"
      >
        Any data not recover after deleting and removing.
      </DialogContentText>
      <DialogActions>
        <Button
          className="delete-project-dialog-btn"
          onClick={handleSubmit}
          autoFocus
        >
          YES, DELETE
        </Button>
        <Button
          className="delete-project-dialog-cancel-btn"
          onClick={() => handleClose()}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
