import React from "react";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
} from "@mui/material";
export default function DashboardTabs({ listMenuItem, setListMenuItem, open }) {
  const updateList = (index) => {
    setListMenuItem((prevList) =>
      prevList.map((item, i) =>
        i === index ? { ...item, show: true } : { ...item, show: false }
      )
    );
  };
  return listMenuItem.map((item, index) => (
    <ListItem
      key={item}
      // disablePadding
      sx={{
        display: "flex",
        background: item.show ? "#DEEBFF" : "#0065FF",
      }}
    >
      <ListItemButton
        onClick={() => updateList(index)}
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 2.1,
        }}
      >
        <ListItemIcon
          sx={{
            width: "16px",
            height: "16px",
            position: "absolute",
            mr: open ? 2 : "auto",
            justifyContent: "center",
          }}
        >
          {item.show ? (
            <img src={item.inActiveImg} alt={item.name} />
          ) : (
            <img src={item.activeImg} alt={item.name} />
          )}
        </ListItemIcon>
        <ListItemButton
          primary={item.name}
          sx={{
            position: "absolute",
            paddingLeft: "80px",
            opacity: open ? 1 : 0,
          }}
        />
        <Typography
          sx={{
            position: "absolute",
            paddingLeft: "80px",
            color: item.show ? "#0065FF" : "white",
            opacity: open ? 1 : 0,
          }}
        >
          {item.name}
        </Typography>
      </ListItemButton>
    </ListItem>
  ));
}
