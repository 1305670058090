import * as React from "react";
import Box from "@mui/system/Box";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import SignSideCard from "../../components/SignSideCard";
import { InputBoxCard } from "../../components/InputBoxCard";

export default function SignUp() {
  let navigate = useNavigate();
  const handleBack = (event) => {
    navigate("/");
  };
  const handleSignIn = () => {
    navigate("/sign-in");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate("/sign-up-verify");
    // Your form submission logic here
  };
  return (
    <Box className="sigin-card">
      <SignSideCard />
      <Box className="sigin-form-container">
        <Button className="sign-in-btn" onClick={handleBack}>
          <img src="/images/arrow_back.svg" alt="back" />
          <span style={{ paddingLeft: "4px" }}>GO TO HOME</span>
        </Button>
        <span className="signin-title" style={{ marginTop: "50px" }}>
          Start your free trial.
        </span>
        <span className="signin-title" style={{ marginTop: "12px" }}>
          Sign up now
        </span>
        <img
          src="/images/sign-google.svg"
          alt="sign-in"
          style={{ marginTop: "30px", cursor: "pointer" }}
        />
        <form onSubmit={handleSubmit} className="signup-form">
          <InputBoxCard
            label="Business Email"
            inputType="email"
            placeholder="Enter Email Id"
            requiredState={true}
          />
          <span className="signin-terms">
            By signing up, I agree to the Costan privacy policy and terms of
            services.
          </span>
          <Button
            variant="contained"
            className="dashboard-create-btn"
            type="submit"
            sx={{ background: "#0065FF", marginTop: "30px" }}
          >
            <span>Sign Up</span>
          </Button>
        </form>
        <span className="signin-terms">
          Have your business already on Costan?{" "}
          <span
            style={{ color: "#0065FF", cursor: "pointer" }}
            onClick={handleSignIn}
          >
            Sign In
          </span>
        </span>
      </Box>
    </Box>
  );
}
