import React from "react";
import { BlockNoteView, useBlockNote } from "@blocknote/react";
import "@blocknote/core/style.css";
import { Box, Button } from "@mui/material";

function RichTextEditor() {
  const [data, setData] = React.useState("");
  const [showRichEditor, setShowRichEditor] = React.useState(true);
  const editor = useBlockNote({
    onEditorContentChange: (editor) => {
      setData(editor._tiptapEditor.getHTML());
      console.log({ editor })
    },
  });
  const StringToHTMLConverter = ({ htmlString }) => {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: htmlString }}
        onClick={() => setShowRichEditor(true)}
      />
    );
  };
  const handelSave = () => {
    setShowRichEditor(false);
  };
  return (
    <Box sx={{ marginBottom: "40px" }}>
      {showRichEditor && (
        <Box
          sx={{
            display: "flex",
            flexFlow: "row",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <BlockNoteView editor={editor} />
          </Box>
          <Box
            sx={{
              alignSelf: "flex-start",
              position: "fixed",
              right: "25%",
              bottom: "4%",
            }}
          >
            <Button
              variant="contained"
              className="dashboard-create-btn"
              onClick={handelSave}
              sx={{
                background: "#0065FF",
                alignSelf: "flex-end",
              }}
            >
              <span>Save</span>
            </Button>
          </Box>
        </Box>
      )}
      {!showRichEditor && (
        <Box className="editor-box">
          <StringToHTMLConverter htmlString={data} />
        </Box>
      )}
    </Box>
  );
}

export default RichTextEditor;
