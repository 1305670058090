import * as React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Grid,
  Select,
  MenuItem,
} from "@mui/material";
import { InputBoxCard } from "./InputBoxCard";
export default function EditProfile({
  openEditProfile,
  setOpenEditProfile,
  editName,
  editRole,
  whatsappNo,
}) {
  const [selectedRole, setSelectedRole] = React.useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "role") {
      setSelectedRole(value);
    }
  };
  const [selectedFile, setSelectedFile] = React.useState(null);
  console.log(selectedFile);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleUpload = () => {
    // You can implement your upload logic here
    if (selectedFile) {
      console.log(`Uploading file: ${selectedFile.name}`);
      // You can send the file to your server or perform any other actions here
    } else {
      console.log("No file selected");
    }
  };
  const handleClose = () => {
    setOpenEditProfile(false);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    handleClose();
    handleUpload();
    // Your form submission logic here
  };
  return (
    <div>
      <Dialog
        open={openEditProfile}
        onClose={() => handleClose()}
        sx={{
          "& .MuiPaper-elevation": { width: "677px", pb: "14px" },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          className="create-project-dialog"
          id="alert-dialog-title"
          sx={{ pb: "20px", pt: "30px" }}
        >
          Edit profile
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: "flex",
              flexFlow: "row",
              justifyContent: "space-between",
            }}
          >
            <DialogContent sx={{ paddingTop: "0px", width: "67%" }}>
              <InputBoxCard
                label="Name"
                handleChange={handleChange}
                inputType="text"
                name="name"
                placeholder="Enter Plateform Name"
                requiredState={false}
                showStar="none"
              />
              <div
                className="create-project-container"
                style={{ width: "100%", marginTop: "2px" }}
              >
                <span
                  className="create-project-title"
                  style={{ marginBottom: "8px" }}
                >
                  Role
                </span>
                <Select
                  value={selectedRole}
                  name="role"
                  onChange={(e) => handleChange(e)}
                  style={{
                    height: "44px",
                  }}
                  sx={{
                    "& .MuiSelect-select": {
                      color: "#17173a",
                      fontFamily: '"Manrope", sans-serif',
                      fontSize: "14px",
                      width: "100%",
                      fontWeight: "500",
                    },
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="Youtube">Youtube</MenuItem>
                  <MenuItem value="Linkedin">Linkedin</MenuItem>
                  <MenuItem value="Instagram">Instagram</MenuItem>
                </Select>
              </div>
              <InputBoxCard
                label="WhatsApp Mobile no"
                inputType="text"
                name="whatsapp"
                handleChange={handleChange}
                placeholder="eg. +91 - 1234567890"
                showStar="none"
                requiredState={false}
              />
            </DialogContent>
            <DialogContent sx={{ padding: "0px" }}>
              <Box className="setting-profile-edit">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  id="image-upload"
                />
                {selectedFile && (
                  <img
                    className="setting-profile-img"
                    src={URL.createObjectURL(selectedFile)}
                    alt="Selected"
                    style={{ objectFit: "contain" }}
                  />
                )}
                <label
                  htmlFor="image-upload"
                  className="setting-profile-upload-btn"
                >
                  <Button sx={{ minWidth: "36px" }} component="span">
                    <img src="/images/upload.svg" alt="upload" />
                  </Button>
                  <Button sx={{ minWidth: "36px" }} component="span">
                    <img src="/images/close.svg" alt="close" />
                  </Button>
                </label>
              </Box>
            </DialogContent>
          </Box>
          <DialogActions sx={{ borderTop: "2px solid #DFE1E6" }}>
            <Button
              className="create-project-dialog-cancel-btn"
              onClick={() => handleClose()}
            >
              Cancel
            </Button>
            <Button
              className="create-project-dialog-btn"
              type="submit"
              autoFocus
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
