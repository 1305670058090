import * as React from "react";
import Box from "@mui/system/Box";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

export default function HomePage() {
  let navigate = useNavigate();
  const handleSignup = () => {
    navigate("/sign-up");
  };
  const handleSignIn = () => {
    navigate("/sign-in");
  };
  return (
    <Box sx={{ display: "flex", flexFlow: "column" }}>
      <a href={`https://api.costan.in/api/connect/auth0`}>
    <button style={{ width: '150px' }}>Login / Register </button>
  </a>
      <Box className="homepage-container">
        <img
          src="images/sigin_costan.svg"
          alt="costan"
          style={{ width: "114px", marginTop: "40px" }}
        />
        <Button
          variant="outlined"
          className="dashboard-skip-btn"
          onClick={handleSignIn}
          sx={{
            background: "#fff",
            marginLeft: "20px",
            marginTop: "30px",
          }}
        >
          <span>Login</span>
        </Button>
      </Box>
      <Box className="homepage-card">
        <img
          src="images/homepage-title.svg"
          alt="home-title"
          style={{ width: "514px", marginTop: "90px" }}
        />
        <span className="homepage-info">
          Costan is the next-generation tool for content creators or
          influencers.
        </span>
        <Box sx={{ marginBottom: "48px" }}>
          <img
            src="images/youtube-app.svg"
            alt="home-title"
            style={{ width: "44px", marginRight: "37px", cursor: "pointer" }}
          />
          <img
            src="images/instagram.svg"
            alt="home-title"
            style={{ width: "44px", marginRight: "37px", cursor: "pointer" }}
          />
          <img
            src="images/tiktok.svg"
            alt="home-title"
            style={{ width: "44px", marginRight: "37px", cursor: "pointer" }}
          />
          <img
            src="images/podcast.svg"
            alt="home-title"
            style={{ width: "44px", marginRight: "37px", cursor: "pointer" }}
          />
          <img
            src="images/linkedin-app.svg"
            alt="home-title"
            style={{ width: "44px", marginRight: "37px", cursor: "pointer" }}
          />
          <img
            src="images/twitter.svg"
            alt="home-title"
            style={{ width: "44px", marginRight: "37px", cursor: "pointer" }}
          />
        </Box>
        <Box sx={{ marginBottom: "90px" }}>
          <Button
            variant="outlined"
            className="dashboard-skip-btn"
            type="submit"
            sx={{
              background: "#fff",
              marginLeft: "20px",
              marginTop: "30px",
            }}
          >
            <span>DEMO Dashboard</span>
          </Button>
          <Button
            variant="contained"
            className="dashboard-create-btn"
            onClick={handleSignup}
            sx={{
              background: "#0065FF",
              marginTop: "30px",
              marginLeft: "20px",
            }}
          >
            <span>Sign Up</span>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
