import * as React from "react";
import Box from "@mui/system/Box";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import SignSideCard from "../../components/SignSideCard";
import { InputBoxCard } from "../../components/InputBoxCard";

export default function EmailConfirm() {
  let navigate = useNavigate();
  const handleBack = (event) => {
    navigate("/home");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate("/home");
    // Your form submission logic here
  };
  return (
    <Box className="sigin-card">
      <SignSideCard />
      <Box className="sigin-form-container">
        <Button className="sign-in-btn" onClick={handleBack}>
          <img src="/images/arrow_back.svg" alt="back" />
          <span style={{ paddingLeft: "4px" }}>BACK</span>
        </Button>
        <form
          onSubmit={handleSubmit}
          style={{
            width: "66%",
            marginTop: "40px",
            paddingRight: "20px",
          }}
        >
          <span className="signin-title">
            Costan is better with teammates! Try adding a few of yours.
          </span>
          <InputBoxCard
            label="Email"
            inputType="email"
            showStar="none"
            placeholder="Eg. Neha@gmail.com"
            requiredState={false}
          />
          <Button
            variant="contained"
            className="dashboard-create-btn"
            type="submit"
            sx={{ background: "#0065FF", marginTop: "30px" }}
          >
            <span>NEXT</span>
          </Button>
          <Button
            variant="outlined"
            className="dashboard-skip-btn"
            type="submit"
            sx={{
              background: "#fff",
              marginLeft: "20px",
              marginTop: "30px",
            }}
          >
            <span>SKIP</span>
          </Button>
        </form>
      </Box>
    </Box>
  );
}
