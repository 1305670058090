import * as React from "react";
import Box from "@mui/system/Box";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import SignSideCard from "../../components/SignSideCard";

export default function SignInVerifyEmail() {
  let navigate = useNavigate();
  const handleBack = () => {
    navigate("/sign-in-details");
  };
  const handleSubmit = () => {
    navigate("/sign-in-welcome");
  };
  return (
    <Box className="sigin-card">
      <SignSideCard />
      <Box className="sigin-form-container">
        <Button
          className="sign-in-btn"
          sx={{ marginTop: "40px" }}
          onClick={handleBack}
        >
          <img src="/images/arrow_back.svg" alt="back" />
          <span style={{ paddingLeft: "4px" }}>BACK</span>
        </Button>
        <span className="signin-title" style={{ marginTop: "140px" }}>
          Please verify your email
        </span>
        <span className="signup-title-verify">
          We sent an verification link. Please verify your email
          <br />
          address, you and your team can get started in costan.
        </span>
        <img
          src="/images/gmail.svg"
          onClick={handleSubmit}
          alt="sign-in"
          style={{ marginTop: "20px", cursor: "pointer", width: "192px" }}
        />
        <span className="signin-terms">
          Didn’t receive and email?{" "}
          <span style={{ color: "#0065FF", cursor: "pointer" }}>
            Resend email
          </span>
        </span>
      </Box>
    </Box>
  );
}
