import * as React from "react";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import RichTextEditor from "./RichTextEditor";
import VideoPlayer from "./VideoPlayer";

const status = {
  inProcess: {
    bgcolor: "#fff8e7",
    colour: "#ffcf5c",
  },
  published: {
    bgcolor: "#E7EFE7",
    colour: "#00C48C",
  },
  onHold: {
    bgcolor: "#FFE7E7",
    colour: "#F05C5C",
  },
};
export default function ProjectEditor({ setShowEditor }) {
  const handleBack = (event) => {
    setShowEditor(true);
  };

  return (
    <Box sx={{ display: "flex", flexFlow: "column" }}>
      <Card
        sx={{
          boxShadow: "none",
        }}
      >
        <CardContent className="editor-project-card">
          <Button
            onClick={handleBack}
            sx={{
              backgroundColor: "#fff",
              minWidth: "32px",
              height: "32px",
              borderRadius: "4px",
              marginRight: "20px",
            }}
          >
            <img src="/images/arrow_back.svg" alt="back" />
          </Button>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <img src="images/YouTube.svg" alt="project-card" />
                <span className="dashboard-project-name">Youtube</span>
                <span
                  className="dashboard-project-status"
                  style={{
                    color: status.inProcess.colour,
                    background: status.inProcess.bgcolor,
                  }}
                >
                  IN PROGRESS
                </span>
              </Box>
              <Box>
                <Button
                  onClick={handleBack}
                  sx={{
                    minWidth: "32px",
                    height: "32px",
                    marginRight: "6px",
                  }}
                >
                  <img src="/images/project-download.svg" alt="back" />
                </Button>
                <span className="editor-update-date">
                  Last updated: 22 Jan 2021 10:30AM
                </span>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "12px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "4px",
                  cursor: "pointer",
                }}
              >
                <span className="dashboard-project-details">
                  LOANS JALDI REPAY KARIYE! | Pay Off Debt Faster!
                </span>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img src="/images/clock.svg" alt="clock" />
                <span className="dashboard-project-timer">
                  2 Days to publish
                </span>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
                justifyContent: "space-between",
              }}
            >
              <Typography
                className="dashboard-sub-title"
                sx={{ color: "#6F6F8D" }}
              >
                How to repay loans quickly? How to reduce interest on loans?
                <span
                  style={{
                    color: "#0065FF",
                    cursor: "pointer",
                    marginLeft: "6px",
                  }}
                >
                  Read more
                </span>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  alignSelf: "flex-start",
                }}
              >
                <img src="/images/Avatar1.svg" alt="clock" />
                <img
                  src="/images/Avatar2.svg"
                  alt="clock"
                  style={{ marginLeft: "-5px" }}
                />
                <img
                  src="/images/Avatar3.svg"
                  alt="clock"
                  style={{ marginLeft: "-5px" }}
                />
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
      {/* -------------Editor--------------- */}
      <RichTextEditor />
      {/* <VideoPlayer /> */}
    </Box>
  );
}
