import * as React from "react";
import Box from "@mui/system/Box";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import SignSideCard from "../../components/SignSideCard";
import { InputBoxCard } from "../../components/InputBoxCard";
import { useDispatch } from "react-redux";
import { fetchUser } from "../../utils/actions";
export default function SignIn() {
  const dispatch = useDispatch();

  const [credentials, setCredentials] = React.useState();

  const handleInputChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  let navigate = useNavigate();
  const handleBack = () => {
    navigate("/");
  };
  const handleSignUp = () => {
    navigate("/sign-up");
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (credentials) {
      dispatch(fetchUser(credentials));
    }
    navigate("/sign-in-details");
  };

  return (
    <Box className="sigin-card">
      <SignSideCard />
      <Box className="sigin-form-container">
        <Button className="sign-in-btn" onClick={handleBack}>
          <img src="/images/arrow_back.svg" alt="back" />
          <span style={{ paddingLeft: "4px" }}>GO TO HOME</span>
        </Button>
        <span className="signin-title" style={{ marginTop: "50px" }}>
          Sign in to your account
        </span>
        <img
          src="/images/sign-google.svg"
          alt="sign-in"
          style={{ marginTop: "30px", cursor: "pointer" }}
        />
        <form onSubmit={handleSubmit} className="signup-form">
          <InputBoxCard
            label="Email Address"
            inputType="email"
            name="email"
            handleChange={handleInputChange}
            placeholder="Enter Email Address"
            requiredState={true}
          />
          <span className="signin-terms">
            By signing up, I agree to the Costan privacy policy and terms of
            services.
          </span>
          <Button
            variant="contained"
            className="dashboard-create-btn"
            type="submit"
            sx={{ background: "#0065FF", marginTop: "30px" }}
          >
            <span>CONTINUE</span>
          </Button>
        </form>
        <span className="signin-terms">
          Don’t have a Costan account yet?
          <span
            style={{ color: "#0065FF", cursor: "pointer", marginLeft: "4px" }}
            onClick={handleSignUp}
          >
            Sign Up
          </span>
        </span>
      </Box>
    </Box>
  );
}
