import { Button } from "@mui/material"
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const  AuthExample = (props) => {
  console.log({ props });
  const location = useLocation();
  const params = useParams();
  const history = useNavigate();

  useEffect(() => {
    console.log({
      location, params, history 
    });
  })


    const handleClick = () => {
        // fetch('http://54.145.103.56:1337/api/auth/auth0/callback
        fetch('https://dev-whx07qqb7xz68zif.us.auth0.com/authorize?client_id=FiGYXVEiQqP1sXmqHvDVSY0zQLpW5Vb7&response_type=code&redirect_uri=https://db58-183-83-55-128.ngrok-free.app/connect/auth0&scope=openid%20email%20profile', {
  method: 'GET',
  mode: "cors",
  headers: {
    'Accept': 'application/json',
    'Authorization': 'Bearer 4692a67c83ad1336d314c2e965976191d6e7cc3fe6c31fcdf2dea51f869fc60bf9b54467e073b5ec8b449246aa7b9364956979d52493188b17f28a0ddbef5d583fc78126983d270bd29ee46c38e629b55f5112f5b489b40c6963a8036e2a6cee5ce08688f197cf136fa51d89f228ebdf30cf5aa7d5adbae4f72afb73fa9b283b'
  }
})
  .then(response => response.json())
  .then(data => {
    // Handle the response data here
    console.log({ data })
  })
  .catch(error => {
    // Handle any errors here
  });

    }
    return(
        <>
            <Button onClick={handleClick}>CLick here</Button>
        </>
    )
}

export default AuthExample;